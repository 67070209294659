import axios from "axios";
import websiteURL from "@/websiteURL";

export async function EditProduct(
  productId,
  product,
  wptFieldProprietes,
  wptFieldAvantages,
  wptFieldInformations,
  wcpoa_attachment_ext_url
) {
  const updatedProduct = {
    ...product,
    meta_data: [
      ...product.meta_data,
      { key: "_wpt_field_proprietes", value: wptFieldProprietes },
      { key: "_wpt_field_avantages", value: wptFieldAvantages },
      {
        key: "_wpt_field_informations-complementaires",
        value: wptFieldInformations,
      },
      {
        key: "wcpoa_attachment_ext_url",
        value: wcpoa_attachment_ext_url,
      },
    ],
  };
  try {
    const apiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${productId}`;

    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const productResponse = await axios.put(apiUrl, updatedProduct, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    return productResponse.data.permalink;
  } catch (error) {
    console.error("Error editing product:", error);
    throw error;
  }
}

//-------------------------------------------------//
export async function AddProduct(product) {
  try {
    product.meta_data = [
      ...(product.informations
        ? [
            {
              key: "_wpt_field_informations-complementaires",
              value: product.informations,
            },
          ]
        : []),

      ...(product.Avantages
        ? [
            {
              key: "_wpt_field_avantages",
              value: product.Avantages,
            },
          ]
        : []),

      ...(product.Proprietes
        ? [
            {
              key: "_wpt_field_proprietes",
              value: product.Proprietes,
            },
          ]
        : []),

      {
        key: "_wp_page_template",
        value: "default",
      },

      {
        key: "wcpoa_attachments_id",
        value: [product.attachmentInfo, ""],
      },
      {
        key: "wcpoa_attachment_name",
        value: [product.attachment_name.toString(), ""],
      },
      {
        key: "wcpoa_attach_type",
        value: ["file_upload", "file_upload"],
      },
      {
        key: "wcpoa_attachment_url",
        value: [product.attachmentInfo, ""],
      },
      {
        key: "wcpoa_attachment_ext_url",
        value: [product.attachmentInfo, ""],
      },
      {
        key: "wcpoa_attachment_description",
        value: ["", ""],
      },
      {
        key: "wcpoa_order_status",
        value: "wc-all",
      },
      {
        key: "wcpoa_product_open_window_flag",
        value: ["yes", "no"],
      },
      {
        key: "wcpoa_product_page_enable",
        value: ["yes", "yes"],
      },
      {
        key: "wcpoa_product_logged_in_flag",
        value: ["no", "no"],
      },
      {
        key: "wcpoa_expired_date_enable",
        value: ["no", "no"],
      },
      {
        key: "wcpoa_expired_date",
        value: ["", ""],
      },
      {
        key: "shopengine_product_views_count",
        value: "12",
      },
      {
        key: "ekit_post_views_count",
        value: "19",
      },
      {
        key: "xs_shared_count_2938_facebook",
        value: "1",
      },
    ];
    product.manage_stock = true;
    const productApiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const productResponse = await axios.post(productApiUrl, product, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    return productResponse.data.permalink;
  } catch (error) {
    console.error("Error adding product and attachments:", error);
    throw error;
  }
}
export async function getParentCategories() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/categories";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const params = {
      context: "view",
      per_page: 100,
      order: "asc",
      orderby: "name",
      hide_empty: "false",
      _fields: "id,name,parent",
    };

    const response = await axios.get(apiUrl, {
      params,
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    const categories = response.data;
    const parentCategories = [];
    const subCategories = {};

    categories.forEach((category) => {
      if (!category.parent) {
        parentCategories.push(category);
      } else {
        if (!subCategories[category.parent]) {
          subCategories[category.parent] = [];
        }
        subCategories[category.parent].push(category);
      }
    });

    parentCategories.forEach((parentCategory) => {
      if (subCategories[parentCategory.id]) {
        parentCategory.subcategories = subCategories[parentCategory.id];
      }
    });

    return parentCategories;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
}
export async function getBrands() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/brands";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const params = {};

    const response = await axios.get(apiUrl, {
      params,
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    const brands = response.data;
    return brands;
  } catch (error) {
    console.error("Erreur lors de la récupération des marques :", error);
    return [];
  }
}
export async function getAttributeTerms() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/attributes";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const attributesResponse = await axios.get(apiUrl, {
      params: {
        _fields: "id,name,terms",
      },
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    const attributes = attributesResponse.data;
    const termPromises = [];

    for (const attribute of attributes) {
      const termsUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/attributes/${attribute.id}/terms`;

      termPromises.push(
        axios.get(termsUrl, {
          params: {
            _fields: "id,name",
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        })
      );
    }

    // Wait for all term requests to complete in parallel
    const termResponses = await Promise.all(termPromises);

    // Combine attributes with their terms
    const allTerms = attributes.map((attribute, index) => {
      attribute.terms = termResponses[index].data;
      return attribute;
    });

    return allTerms;
  } catch (error) {
    console.error("Error fetching attributes and terms:", error);
    return [];
  }
}
