<template>
  <div>
    <div>
      <div class="card mb-4">
        <div class="card-header"></div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h6>Ajouter un Nouvel Attribut :</h6>
              <b-button class="col-3 col-lg-3" variant="outline-dark"
                >Ajouter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      :items="items"
      :fields="fields"
      striped
      hover
      responsive
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left: 5px">Loading...</strong>
        </div>
      </template>
      <template #cell(terms)="row">
        <div>
          <b-badge
            v-for="item in row.item.terms"
            :key="item.id"
            style="margin: 5px"
            pill
            variant="light-dark"
            class="text-capitalize"
          >
            {{ item.name }}
          </b-badge>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap" @click="RedirectToEditProduct(data.item.id)">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
            @click="RedirectToEditProduct(data.item.id)"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            :icon="data.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
            size="16"
            class="mx-1"
            @click="data.toggleDetails"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'apps-invoice-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="perPage"
      aria-controls="product-list"
      size="md"
      align="center"
      class="my-4"
    />
  </div>
</template>

<script>
import { getAttributeTerms } from "../../services/products/Products";
import {
  BPagination,
  BFormInput,
  BCardText,
  BButton,
  BIcon,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BTable,
  BSpinner,
  BImg,
  BLink,
  BTooltip,
  bIcon,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import websiteURL from "@/websiteURL";
export default {
  components: {
    BPagination,
    BFormInput,
    BCardText,
    vSelect,
    BSpinner,
    BTooltip,
    BDropdownItem,
    BDropdown,
    BIcon,
    BTable,
    BImg,
    BLink,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  setup() {},
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      isBusy: false,
      items: [],
      fields: ["id", "name", "type", "slug", "terms", "actions"],
    };
  },
  computed: {},
  methods: {},

  async mounted() {
    this.isBusy = true;
    this.items = await getAttributeTerms();
    this.isBusy = false;
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
